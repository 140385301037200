//Load Libraries
require('flatpickr/dist/flatpickr.min');
require('magnific-popup/dist/jquery.magnific-popup.min');
require('select2/dist/js/select2.full.min');
// require('@accessible360/accessible-slick/slick/slick.min');
require('slick-carousel/slick/slick');

//Load Modules
require('./modules/accordion');
require('./modules/animatein');
require('./modules/bannerVideo');
require('./modules/formInputs');
require('./modules/menu');
require('./modules/modal');
require('./modules/scrollTo');
require('./modules/searchToggle');
require('./modules/shareSocial');
require('./modules/sideMenu');
require('./modules/tabs');

// **************
// Shrink nav on scroll
// **************
var position = $(window).scrollTop();

$(window).scroll(function() {
    var scroll = $(window).scrollTop();
    if (scroll >= 74) {
        $('body').addClass('__stickyheader');
    }
    if (scroll < 74) {
        $('body').removeClass('__stickyheader');
    }
    var scroll = $(window).scrollTop();
    position = scroll;
});

// **************
// Newsletter modal
// **************
$(function() {
    modal = $('#newsletterModal');

    // if checkbox clicked already
    if (!$('.newsletter-modalform input[type="checkbox"]').prop('checked')) {
        modal.find('button[type="submit"]').attr('disabled', true);
    }

    // if form error
    if (modal.find('.error_feedback').length > 0) {
        modal.addClass('__active');
    }

    // on modal open
    $('#openNewsletterModal').on('click', function() {
        var newsletterEmailField = $(this)
            .closest('.newsletter-form')
            .find('#modaltrigger-email');
        modal.addClass('__active');
        if (newsletterEmailField.val().trim() !== '') {
            modal.find('input[type="email"]').val(newsletterEmailField.val());
        } else {
            modal.find('input[type="email"]').val('');
        }

        // on checkbox tick
        var modalSubmit = modal.find('button[type="submit"]');
        $('.newsletter-modalform input[type="checkbox"]').on(
            'click',
            function() {
                if ($(this).prop('checked')) {
                    modalSubmit.attr('disabled', false);
                } else {
                    modalSubmit.attr('disabled', true);
                }
            }
        );
    });
});
