/* share social */
$(function() {
    var toggle = $('.socialshare-title');
    var shareicons = $('.socialshare-socialset');

    toggle.click(function(event) {
        event.preventDefault();
        $(this)
            .siblings('.socialshare-socialset')
            .toggleClass('__active');
    });
});
