/* modal */
function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000 );
    var expires = 'expires=' + d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
}
function getCookie(cname) {
    var name = cname + '=';
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
}
$(function () {
    var trigger = $('.ModalTrigger');
    var modal = $('.Modal');
    var close = $('.ModalClose');
    var body = $('body');

    trigger.click(function (event) {
        event.preventDefault();
        var modal_id = $(this).data('modal');
        modal.filter('[data-modal="' + modal_id + '"]').addClass('__active');
        body.addClass('__noscroll');
    });

    close.click(function () {
        modal.removeClass('__active');
        body.removeClass('__noscroll');
    });

    $('.Modal').not('#formModal').click(function (event) {
        if (!$(event.target).closest('.modalblock-content').length) {
            $('.Modal').removeClass('__active');
            body.removeClass('__noscroll');
        }
    });

    $(document).keyup(function (e) {
        var formModal = $('#formModal');
        if (e.keyCode == 27 && !formModal.length) {
            $('.Modal.__active').removeClass('__active');
            body.removeClass('__noscroll');
        }
    });
});

// Form Modal
// If the form modal exists, display after 10 seconds
$(function () {
    const formModal = $('#formModal');
    const modal_timer = formModal.data('modaltiming'); //ms
    const modal_id = formModal.data('modal');
    if (formModal.length && modal_id.length) {
        if (getCookie(modal_id) === '') {
            setTimeout(function () {
              formModal.filter('[data-modal="' + modal_id + '"]').addClass('__active');
              formModal.find('.ModalClose').focus();
              $('body').addClass('__noscroll');
            }, modal_timer);
        }
        formModal.find('div, button').on('click', function() {
            if($(this).data('hide-promotion') == 'promotional_popup'){
                setCookie(modal_id, 'true', 30);
                formModal.filter('[data-modal="' + modal_id + '"]').removeClass('__active');
                formModal.removeClass('__noscroll');
            }
        });
    }
});
