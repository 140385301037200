$(function() {
    var break_point = 992;
    var target = $('#Video');
    var source = target.data('video');
    var html = '';

    function append_video() {
        html += '<video autoplay loop muted>';
        html += '<source src="' + source + '" type="video/mp4">';
        html += '</video>';
        html += '<div class="banner-video-controls">';
        html +=
            '<button class="banner-video-controls-button __slidein playpause">';
        html +=
            '<span id="banner-video-controls-button-text" class="sronly">Pause</span>';
        html += '</button>';
        html += '</div>';

        target.prepend(html);
    }

    function pause_play() {
        if (target.length) {
            var video = document.querySelector('video');
            var playPauseButton = document.querySelector('.playpause');
            var playPauseButtonText = document.querySelector(
                '#banner-video-controls-button-text'
            );

            playPauseButton.classList.add('__pause');

            // Play/Pause button
            playPauseButton.onclick = function() {
                if (video.paused) {
                    // is paused, click to play
                    video.play();
                    playPauseButton.classList.remove('__play');
                    playPauseButton.classList.add('__pause');
                    playPauseButtonText.textContent = 'Pause';
                } else {
                    // is playing, click to pause
                    video.pause();
                    playPauseButton.classList.add('__play');
                    playPauseButton.classList.remove('__pause');
                    playPauseButtonText.textContent = 'Play';
                }
            };
        }
    }

    function checker() {
        var browser_width = window.innerWidth;

        if (browser_width >= break_point) {
            append_video();
            pause_play();
        } else if (browser_width <= break_point) {
            target.find('video').remove();
        }
    }
    checker();
});
