/* radiobuttons */
$(document).on('change', '.__radiobuttons input', function() {
    if ($(this).is(':checked')) {
        $(this)
            .closest('.__radiobuttons')
            .find('.input_wrap > label')
            .removeClass('__checked');
        $(this)
            .parent()
            .addClass('__checked');
    }
});
$('.__radiobuttons input:checked')
    .parent()
    .addClass('__checked');

/* checkboxes */
$(document).on('change', '.__checkboxes input', function() {
    if ($(this).is(':checked')) {
        $(this)
            .parent()
            .addClass('__checked');
    } else {
        $(this)
            .parent()
            .removeClass('__checked');
    }
});
$('.__checkboxes input:checked')
    .parent()
    .addClass('__checked');

/* text inputs */
$(document).on('focus', '.__shrinkingLabels input', function() {
    $(this)
        .parent()
        .parent()
        .addClass('__selected');
});
$(document).on('blur', '.__shrinkingLabels input', function() {
    if (!$(this).val()) {
        $(this)
            .parent()
            .parent()
            .removeClass('__selected');
    }
});
$('.__shrinkingLabels input').each(function() {
    if ($(this).val()) {
        $(this)
            .parent()
            .parent()
            .addClass('__selected');
    }
});
