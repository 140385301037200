$('#ScrollTo').click(function(event) {
    var scrollTarget = '#backToTop';
    event.preventDefault();
    $('html, body').animate(
        { scrollTop: $(scrollTarget).offset().top },
        1000,
        function() {
            location.hash = scrollTarget;
            $(scrollTarget).focus();
            if ($(scrollTarget).is(':focus')) {
                return !1;
            } else {
                $(scrollTarget).attr('tabindex', '-1');
                $(scrollTarget).focus();
            }
        }
    );
});

$('#ScrollToBodycontent').click(function(event) {
    var scrollTarget = '#Bodycontent';
    event.preventDefault();
    $('html, body').animate(
        { scrollTop: $(scrollTarget).offset().top },
        1000,
        function() {
            location.hash = scrollTarget;
            $(scrollTarget).focus();
            if ($(scrollTarget).is(':focus')) {
                return !1;
            } else {
                $(scrollTarget).attr('tabindex', '-1');
                $(scrollTarget).focus();
            }
        }
    );
});
