/* sidemenu */
$(function() {
    var toggle = $('.sidemenu-toggle');
    toggle.click(function(event) {
        event.preventDefault();
        $(this)
            .parent()
            .toggleClass('__active');
        $(this)
            .siblings()
            .slideToggle();
    });
});
